import request from "@/utils/request";

export function getListEtest(data) {
  return request({
    method: "POST",
    url: "e_test/get_class",
    data: data
  });
}

export function getClassDetail(data) {
  return request({
    method: "POST",
    url: "e_test/get_class_detail",
    data: data
  });
}

export function createEtest(data) {
  return request({
    method: "POST",
    url: "e_test/create",
    data: data
  });
}

export function deleteEtest(data) {
  return request({
    method: "POST",
    url: "e_test/delete",
    data: data
  });
}

export function eTestLessonList(data) {
  return request({
    method: "POST",
    url: "e_test/etest_lesson_list",
    data: data
  });
}

export function getClassEtest(data) {
  return request({
    method: "POST",
    url: "e_test/class_in_etest",
    data: data
  });
}

export function submitQuestionEtest(data) {
  return request({
    method: "POST",
    url: "e_test/add_question",
    data: data
  });
}

export function updateMainQuestion(data) {
  return request({
    method: "POST",
    url: "e_test/update_main",
    data: data
  });
}

export function updateStatusQuestion(data) {
  return request({
    method: "POST",
    url: "e_test/update_status",
    data: data
  });
}

export function publishQuestion(data) {
  return request({
    method: "POST",
    url: "e_test/publish",
    data: data
  });
}

export function unpublishQuestion(data) {
  return request({
    method: "POST",
    url: "e_test/unpublish",
    data: data
  });
}

export function getQuestionBankLocal(data) {
  return request({
    method: "POST",
    url: "e_test/question_bank_list",
    data: data
  });
}

export function addFromBank(data) {
  return request({
    method: "POST",
    url: "e_test/add_from_bank",
    data: data
  });
}

export function getQuestionBankGlobal(data) {
  return request({
    method: "POST",
    url: "question_bank/list",
    data: data
  });
}

export function setQuestionBankGlobal(data) {
  return request({
    method: "POST",
    url: "question_bank/create",
    data: data
  });
}

export function setQuestionBankGlobalExcel(data) {
  return request({
    method: "POST",
    url: "question_bank/upload",
    data: data
  });
}

export function setQuestionBankKD(data) {
  return request({
    method: "POST",
    url: "question_bank/set_kd",
    data: data
  });
}

// COC: Code of Conduct
export function setCOCGlobal(data) {
  return request({
    method: "POST",
    url: "e_test/set_etest_regulation",
    data: data
  });
}

export function getCOCGlobal(data) {
  return request({
    method: "POST",
    url: "e_test/get_etest_regulation",
    data: data
  });
}

export function setCOCLocal(data) {
  return request({
    method: "POST",
    url: "e_test/set_etest_class_regulation",
    data: data
  });
}

export function getQuestionByID(data) {
  return request({
    method: "POST",
    url: "e_test/class_detail_byid",
    data: data
  });
}

export function updateWeightGlobal(data) {
  return request({
    method: "POST",
    url: "question_bank/update_point",
    data: data
  });
}

export function deleteQuestionBankGlobal(data) {
  return request({
    method: "POST",
    url: "question_bank/delete",
    data: data
  });
}

export function updateWeightLocal(data) {
  return request({
    method: "POST",
    url: "e_test/update_point",
    data: data
  });
}

export function updateQuestionBankGlobal(data) {
  return request({
    method: "POST",
    url: "question_bank/update",
    data: data
  });
}

export function copyQuestionBankLocal(data) {
  return request({
    method: "POST",
    url: "e_test/copy",
    data: data
  });
}

// start endpoint for student
export function listEtestStudent(data) {
  return request({
    method: "POST",
    url: "e_test_student/get_list",
    data: data
  });
}

export function etestStudentStart(data) {
  return request({
    method: "POST",
    url: "e_test_student/start",
    data: data
  });
}

export function questionEtestStudent(data) {
  return request({
    method: "POST",
    url: "e_test_student/get_question",
    data: data
  });
}

export function answerEtestStudent(data) {
  return request({
    method: "POST",
    url: "e_test_student/answer",
    data: data
  });
}
// end endpoint for student

export function listScore(data) {
  return request({
    method: "POST",
    url: "e_test_score/list",
    data: data
  });
}

export function listScoreCorrection(data) {
  return request({
    method: "POST",
    url: "e_test/correction_list",
    data: data
  });
}

export function updatePoint(data) {
  return request({
    method: "POST",
    url: "e_test/correction_points",
    data: data
  });
}

export function listChance(data) {
  return request({
    method: "POST",
    url: "e_test/list_chance",
    data: data
  });
}

export function updateChance(data) {
  return request({
    method: "POST",
    url: "e_test/change_chance",
    data: data
  });
}

export function updateDuration(data) {
  return request({
    method: "POST",
    url: "e_test/change_duration",
    data: data
  });
}

export function listStatistic(data) {
  return request({
    method: "POST",
    url: "e_test/summary",
    data: data
  });
}

export function editEtest(data) {
  return request({
    method: "POST",
    url: "e_test/update",
    data: data
  });
}

export function removeQuestion(data) {
  return request({
    method: "POST",
    url: "e_test/remove_question",
    data: data
  });
}

export function publish_to_student(data) {
  return request({
    method: "POST",
    url: "e_test/publish_to_student",
    data: data
  });
}

export function detailStatistic(data) {
  return request({
    method: "POST",
    url: "e_test/summary_detail",
    data: data
  });
}

export function detailHistory(data) {
  return request({
    method: "POST",
    url: "e_test_student/history",
    data: data
  });
}

export function listRemidi(data) {
  return request({
    method: "POST",
    url: "remedy/get_class_detail",
    data: data
  });
}

export function listStudentScore(data) {
  return request({
    method: "POST",
    url: "remedy/get_student_create",
    data: data
  });
}

export function createRemedy(data) {
  return request({
    method: "POST",
    url: "remedy/create",
    data: data
  });
}

export function removeRemedy(data) {
  return request({
    method: "POST",
    url: "remedy/delete",
    data: data
  });
}

export function finishEtestStudent(data) {
  return request({
    method: "POST",
    url: "e_test_student/finish",
    data: data
  });
}

export function remedyById(data) {
  return request({
    method: "POST",
    url: "remedy/detail_byid",
    data: data
  });
}

export function getStudent(data) {
  return request({
    method: "POST",
    url: "remedy/get_student_remedy",
    data: data
  });
}

export function updateValueStudent(data) {
  return request({
    method: "POST",
    url: "e_test_score/update_used",
    data: data
  });
}

export function postingValue(data) {
  return request({
    method: "POST",
    url: "e_test_score/posting",
    data: data
  });
}

export function saveExtraTime(data) {
  return request({
    method: "POST",
    url: "e_test/extra_time",
    data: data
  });
}

export function questionList(data) {
  return request({
    method: "POST",
    url: "e_test/question_list",
    data: data
  });
}

export function generateChance(data) {
  return request({
    method: "POST",
    url: "e_test/generate-single",
    data: data
  });
}

export function monitorEtest(data) {
  return request({
    method: "POST",
    url: "e_test_student/monitor",
    data: data
  });
}
