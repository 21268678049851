<template>
  <v-card :class="darkmode || 'grey lighten-5'" class="pa-4" flat>
    <div class="mb-5 font-weight-bold" style="cursor: pointer; width: 100px">
      <v-btn @click="$router.go(-1)" depressed>
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("app.back") }}
      </v-btn>
    </div>
    <HeaderInfo :info="dataTable" showTotal etest title />
    <div :class="darkmode || 'white'">
      <div class="d-flex align-center">
        <v-text-field
          v-model="table.search"
          :label="$t('app.search')"
          outlined
          dense
          hide-details
          clearable
          style="max-width: 250px"
          prepend-inner-icon="mdi-magnify"
          class="d-inline-block mx-3 my-5"
        />
        <div class="d-inline-block font-weight-bold">
          {{ dataTable.body.data.length }}
          {{ $t("e_test.question") }}
        </div>
      </div>
      <v-data-table
        :headers="tableHeaders"
        :items="dataTable.body.data"
        :page.sync="table.page"
        :options.sync="options"
        :loading="table.loading"
        disable-pagination
        item-key="id"
        hide-default-footer
        class="elevation-0 mx-3"
      >
        <template #item.question="{ item }">
          <span v-html="truncateHtml(item.question)" />
        </template>
        <template #item.action="{item}">
          <v-btn
            small
            depressed
            icon
            class="primary"
            @click="$router.push(`/academic/statistics-detail/${item.id}`)"
          >
            <v-icon color="white" small>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Pagination
        :length="dataTable.body.last_page"
        :total="dataTable.body.total"
        :current-page="table.page"
        :limit="table.limit"
        :handler="paginateChange"
      />
    </div>
  </v-card>
</template>

<script>
import store from "@/store";
import { listStatistic } from "@/api/admin/academic/eTest";
import truncate from "truncate-html";

export default {
  components: {
    HeaderInfo: () => import("../../components/HeaderInfo"),
    Pagination: () => import("@/components/Pagination")
  },
  created() {
    this.getStatistic();
  },
  watch: {
    "table.search"() {
      this.getStatistic();
    },
    options: {
      handler(param) {
        if (param.sortBy.length != 0) {
          this.table.sort = [param.sortBy[0]];
          this.table.order = param.sortDesc[0] ? "DESC" : "ASC";
          this.getStatistic();
        }
      }
    }
  },
  computed: {
    darkmode() {
      return store.getters.g_darkmode;
    }
  },
  data() {
    return {
      options: {},
      tableHeaders: [
        {
          text: this.$i18n.t("e_test.table_header.statistics.title"),
          value: "question"
        },
        {
          text: this.$i18n.t(
            "e_test.table_header.statistics.students_correctly"
          ),
          value: "true",
          width: 100
        },
        {
          text: this.$i18n.t(
            "e_test.table_header.statistics.students_are_wrong"
          ),
          value: "false",
          width: 100
        },
        {
          text: this.$i18n.t("e_test.table_header.statistics.not_completing"),
          value: "pass",
          width: 130
        },
        {
          text: this.$i18n.t("e_test.table_header.statistics.status_correctly"),
          width: 100,
          value: "not_corrected"
        },
        {
          text: this.$i18n.t("e_test.table_header.statistics.less_precise"),
          value: "less_precise",
          width: 100
        },
        {
          text: this.$i18n.t("app.action"),
          align: "center",
          sortable: false,
          value: "action",
          width: 100
        }
      ],
      table: {
        limit: 10,
        page: 1,
        search: "",
        loading: false,
        sort: [],
        order: ""
      },
      dataTable: {
        header: {},
        body: { data: [] }
      }
    };
  },
  methods: {
    truncateHtml: html =>
      truncate(html, 10, { byWords: true, stripTags: true }),
    paginateChange(page, limit) {
      this.table.limit = limit;
      this.table.page = page;
      this.getStatistic();
    },
    async getStatistic() {
      this.table.loading = true;
      try {
        const response = await listStatistic({
          etest_class: this.$route.params.etestClass,
          ...this.table
        });
        if (response.data.code) {
          this.dataTable = response.data.data;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.table.loading = false;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    }
  }
};
</script>
